@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

:root {
  --secondary: #16ffe6;
  --primary: #e6e6e6;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}
html {
  font-size: 62.5%;
  font-family: "NotoSans", sans-serif;
  scroll-behavior: smooth;
}
body {
  box-sizing: border-box;
  font-family: Lato;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
li {
  list-style: none;
}
article,
aside,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

/*-- Burger Menu ==*/
.wrapper {
  padding: 1rem;
  width: min(1060px, 95%);
  padding-top: 5rem;
  margin: 0 auto;
}
.menu__lines {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  cursor: pointer;
  z-index: 3;
  position: fixed;
  margin: 2rem;
}
.menu__lines-line {
  display: block;
  position: absolute;
  background: #ffffff;
  height: 2px;
  width: 24px;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.menu__lines-line:nth-child(1) {
  top: 4px;
}
.menu__lines-line:nth-child(2) {
  top: 10px;
}
.menu__lines-line:nth-child(3) {
  top: 16px;
}

.menu__lines.show .menu__lines-line:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}
.menu__lines.show .menu__lines-line:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.menu__lines.show .menu__lines-line:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
}
/*---Navigation---*/
.Navigation {
  width: 100%;
  position: absolute;
  transition: 0.2s;
  top: 0;
  left: 0;
  border-bottom: 2px solid;
  z-index: 1;
  height: 100%;
  position: fixed;
  justify-content: center;
  transform: translateX(-150%);
  height: 100%;
  background-color: #1e212d;
  z-index: 2;
}
.Navigation__content {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.Navigation__content--wrapper {
  padding-top: 1rem;
}
.showNav {
  transform: translateX(0px);
}
.Navigation__list {
  color: #fff;
}
.Navigation__list-item {
  transition: 0.3s;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 2rem;
  font-family: "Bebas Neue", cursive;
  margin: 2rem;
}
.Navigation__list-item:hover {
  opacity: 0.8;
}
.icon {
  display: inline-block;
  width: 3rem;
  height: 2.5rem;
  stroke-width: 0;
  fill: white;
  cursor: pointer;
  transition: 0.2s;
}
.icon:hover {
  fill: #2ad2c7f2;
}

.social {
  padding: 0.5rem;
}

/* ----CUBE ANIMATION----*/
.scene {
  width: 100px;
  height: 100px;
  perspective: 600px;
  margin-left: auto;
  display: none;
  margin-right: auto;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 5rem;
  transform-style: preserve-3d;
  transform: translateZ(-100px);
}

.cube__face {
  position: absolute;
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: inherit;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  background: #1af1dacc;
  box-shadow: inset 0px 0px 16px 1.5px #242323;
  border: 2px solid #0000007a;
}
.cube__face--js {
  transform: rotateY(0deg);
}
.cube__face--css {
  transform: rotateY(90deg);
}
.cube__face--react {
  transform: rotateY(180deg);
}
.cube__face--scss {
  transform: rotateY(-90deg);
}
.cube__face--git {
  transform: rotateX(90deg);
}
.cube__face--nodejs {
  transform: rotateX(-90deg);
}

.cube__face--js {
  transform: rotateY(0deg) translateZ(50px);
}
.cube__face--css {
  transform: rotateY(90deg) translateZ(50px);
}
.cube__face--react {
  transform: rotateX(180deg) translateZ(50px);
}
.cube__face--scss {
  transform: rotateY(-90deg) translateZ(50px);
}
.cube__face--git {
  transform: rotateX(90deg) translateZ(50px);
}
.cube__face--nodejs {
  transform: rotateX(-90deg) translateZ(50px);
}

.cube.show-js {
  transform: translateZ(-100px) rotateY(0deg);
}
.cube.show-css {
  transform: translateZ(-100px) rotateY(-90deg);
}
.cube.show-react {
  transform: translateZ(-100px) rotateY(-180deg);
}
.cube.show-scss {
  transform: translateZ(-100px) rotateY(90deg);
}
.cube.show-git {
  transform: translateZ(-100px) rotateX(-90deg);
}
.cube.show-nodejs {
  transform: translateZ(-100px) rotateX(90deg);
}

.cube {
  animation: revoleve 22s infinite;
  transform-origin: bottom;
}
/*----LANDINGPAGE---*/
.landingpage {
  background: #1e212d;
  min-height: 60vh;
}
.landingpage__content {
  padding-top: 11rem;
  padding-bottom: 5rem;
}

.name {
  font-size: 5rem;
  line-height: 3.7rem;
  color: #fff;
  margin-bottom: 1rem;
}
.landingpage p {
  font-size: 1.8rem;
  color: #fff;
  line-height: 2.1rem;
  letter-spacing: 1px;
  font-family: "NotoSans", sans-serif;
}
.Btns__container {
  margin-top: 2rem;
  text-align: center;
}
.Btn {
  padding: .7em;
  width: 16rem;
  border: 0;
  background: #ffffff;
  color: black;
  text-transform: uppercase;
  outline: none;
  font-size: 1.3rem;
  letter-spacing: 1px;
  cursor: pointer;
  z-index: 0;
  margin-top: .5rem;
  display: block;
  border-radius: 10px;
}
.section {
  position: relative;
  overflow: hidden;
}
#experience {
  background-color: var(--primary);
}
.section__header {
  background: #00ffffb0;
  transform: rotate(-37deg);
  position: absolute;
  height: 121px;
  display: flex;
  width: 324px;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: -20px;
  left: -113px;
  border-radius: 10px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 200;
  padding-top: 1.5rem;
}
.page-header {
  margin-bottom: 50px;
  /* border-bottom: 2px solid var(--primary); */
  padding-bottom: 2px;
  font-size: 2.7rem;
  font-weight: 200;
}
/*-------------Project---------------*/
.projects {

  color: #fff;
  background: #444059f2;
}
.projects__container {
  padding-top: 17rem;
  width: 65%;
  margin: 0 auto;
}

.project {
  /*--------*/
  display: block;
  margin: 0 auto;
  margin-bottom: 4rem;
}
.project__img > * {
  max-width: 100%;
}
img {
  max-width: 100%;
}

.project__details {
  padding-left: 2rem;
  position: relative;
  top: -1rem;
  border-radius: 10px;
  padding-top: 2rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 300px;
  padding-bottom: 2rem;
  align-items: end;
  justify-content: flex-start;
}
.project__details__name {
 
    font-size: 3em;
    background: #323f8d;
    padding: 5px;
    margin-bottom: 1em;
    margin-top: 10px;

}
.project__details__summary {
  font-size: 1.5rem;
}
.project__links {

  display: flex;
  gap: 10px;
  text-align: center;
}
#private:hover {
  cursor:not-allowed;
}
/*------SKILLS------*/
.skillLists {
  display: flex;
  display: flex;
  font-size: 1.7rem;
  justify-content: end;
  font-weight: 200;
  font-family: inherit;
  text-transform: uppercase;
  flex-wrap: wrap;
}
.skill {
  background: #383838a3;
  color: #fff;
  padding: 1rem;
  height: 100px;
  line-height: 100px;
  flex: 0 0 19.2%;
  text-align: center;
  margin-right: 2px;
  margin-top: 2px;
  flex: 180px;
  font-family: Darker Grotesque, sans-serif;
}
.skill-and-certification {
  padding: 3rem 0;
  background: #201f1ffa;
}
.skill-and-certification img {
  max-width: 400px;
}
.certification-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/*-----------ABOUT-------------*/
.about-section {
  background: var(--primary);
}
.about-wrapper {
  padding: 7rem 0;
}
.flex {
  display: block;
  justify-content: space-between;
  align-items: center;
}
.about-text {
  flex: 0 0 45%;
  font-size: 1.7rem;
  color: #5a5956f0;
  padding: 1rem;
}
.about-img {
  flex: 0 0 40%;
  margin-top: 3rem;
  text-align: center;
}
.about-img img {
  max-width: 260px;
  border-radius: 100%;
  box-shadow: 1px 1px 0px 3px var(--secondary);
}
.flex__about {
}

/*------------CONTACT----------*/
.contact-section {
  background: #333;
}
.contact-section .Wrapper {
  padding-bottom: 9rem;
  padding-top: 10rem;
  width: 90%;
  margin: 0 auto;
}
.formContent {
  justify-content: space-between;
  min-height: 400px;
  background: var(--primary-color);
  width: 100%;
  padding: 0;
  display: block;
  box-sizing: border-box;
  border-radius: 20px;
}
.social__bottom {
  text-align: left;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  margin-bottom: 2rem;
}
.social__bottom svg {
  left: 0 !important;
}

.formContent-left {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.formContent-left-header {
  font-size: 3rem;
  color: #fff;
  font-family: Darker Grotesque;
}
.formContent-left svg {
  position: relative;
  left: -83px;
}
.formDiv {
  flex: 0 0 60%;
  padding: 2.5rem;
  background: #3e3e3e75;
  border-radius: 15px;
}
.formDiv input,
.formDiv textarea {
  padding: 1rem 2rem;
  -o-border-image: initial;
  border-image: initial;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgb(255 241 241);
  resize: none;
  color: #fff;
  width: 100%;
  margin-top: 5rem;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #fff;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.formDiv textarea {
  min-height: 100px;
}
.send {
  width: 120px;
  cursor: pointer;
  padding: 0.8rem;
  border-radius: 20px;
  color: #fff;
  background: rgb(29 29 29);
  outline: none;
  border: none;
  font-size: 1.4rem;
  height: 50px;
  margin-top: 3rem;
}
.formDiv input:focus,
.formDiv textarea:focus {
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.050980392156862744);
  border-radius: 15px;
  color: #fff;
}
footer {
  background: #000;
  min-height: 100px;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  padding: 0 2rem;
  font-family: Darker Grotesque;
  font-weight: 700;
  line-height: 100px;
}
.Burger-menu-lines {
  height: 32px;
  width: 32px;
  margin-left: auto;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  display: none;
  z-index: 2000000000;
  position: fixed;
  left: 84%;
  top: 46px;
}
.Burger-menu-line {
  height: 3px;
  width: 100%;
  background: var(--primary-color);
}

/*====KEY FRAMES ===*/
@keyframes revoleve {
  0% {
    transform: rotateX(0deg);
  }
  16% {
    transform: rotateX(83deg);
  }
  32% {
    transform: rotateY(83deg);
  }
  48% {
    transform: rotateY(-83deg);
  }
  64% {
    transform: rotateX(-83deg);
  }
  80% {
    transform: rotateX(-180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

/*-------Media Quries-------*/
@media (min-width: 570px) {
  /* .landingpage{min-height: 100vh; max-height: 100vh;} */
  .landingpage__content {
    display: flex;
    padding-top: 21rem;
  }
  .landingpage__content__intro {
    flex: 0 0 53%;
  }
  /* .scene{display: flex;} */
  .name {
    font-size: 16rem;
    line-height: 11.7rem;
    margin-bottom: 2rem;
  }
  .formContent {
    display: flex;
  }
  .formContent-left-header {
    font-size: 5rem;
  }
}
@media (max-width: 500px) {
  .projects__container {
    width: 95%;
  }
}

@media (min-width: 700px) {
  .flex {
    display: flex;
  }
  .about-text {
    flex: 0 0 60%;
  }
  .about-img {
    flex: 0 0 35%;
  }
  .Navigation {
    transform: translateX(0px);
    height: auto;
    border: none;
    justify-content: space-evenly;
  }
  .formContent {
    padding: 2rem 4rem;
  }
  .menu__lines {
    display: none;
  }
  .about-img img {
    max-width: 80%;
  }

  .Navigation__list {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    align-items: center;
  }
  .Navigation__content {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: 1060px;
  }
  .landingpage__content {
    padding-top: 20rem;
  }
  .Navigation__list-item {
    font-size: 1.6rem;
  }
  .icon {
    height: 2rem;
  }
  .social {
    display: inline;
  }
}

@media (min-width: 750px) {
  .project {
    flex: 0 0 80%;
    display: flex;
    justify-content: center;
  }
  .project__details {
    top: -3rem;
    min-height: 35rem;
    max-height: 35rem;
    padding-left: 5rem;
  }
  .project__img img {
    max-width: 300px;
  }
}

@media (min-width: 800px) {
  .scene {
    display: block;
  }
}
@media (min-width: 900px) {
  .project__img {
    position: relative;
    left: 30px;
    z-index: 1;
    flex: 0 0 480px;
  }
  ..landingpage {
    max-height: 100vh;
    min-height: 100vh;
  }
  .project__img img {
    max-width: 100%;
  }
  .cube__face {
    width: 155px;
    height: 155px;
  }
  .about-wrapper {
    padding: 7rem 7rem;
  }
  .cube__face--js {
    transform: rotateY(0deg) translateZ(75px);
  }
  .cube__face--css {
    transform: rotateY(90deg) translateZ(75px);
  }
  .cube__face--react {
    transform: rotateX(180deg) translateZ(75px);
  }
  .cube__face--scss {
    transform: rotateY(-90deg) translateZ(75px);
  }
  .cube__face--git {
    transform: rotateX(90deg) translateZ(75px);
  }
  .cube__face--nodejs {
    transform: rotateX(-90deg) translateZ(75px);
  }
}
@media (max-width: 1060px) and (max-height: 600px;) and (orientation: landscape) {
  .landingpage {
    min-height: fit-content;
    max-height: fit-content;
  }
}
